// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;600;700&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #553C7F;
$h2-color: #6D9D31;
$h3-bg-color: #553C7F;
$h4-bg-color: #6D9D31;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Catamaran', sans-serif;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/colour-stripe.png) repeat-x;
		width: 100%;
		height: 5px;
		left: 0;
		bottom: 0;
		z-index: 20;

	}
}

.body_5716 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_5716 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	// width: 990px;
	width: 87%;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;

	@media only screen and (max-width: 1500px) {
		width: 94%;
	}

}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_5716 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
	position: relative;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/header-bar.png) no-repeat;
		top: 0;
		left: 0;
		width: 100%;
		height: 70px;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: absolute;
	top: 30px;
	left: 0;
	transition: 0.4s;

	@media only screen and (max-width: 990px) {
		right: 0;
		top: 60px;
	}

	&:hover {
		opacity: 0.85;
	}

	a {
		span {}

		img#logo {
			@media only screen and (max-width: 990px) {
				margin: 0 auto;
			}
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: -30px;
	top: 43px;
	z-index: 99;
	display: flex;
	background: rgba(255, 255, 255, 0.2);
	padding: 5px 13px;
	border-radius: 25px;
	border: 2px solid rgba(255, 255, 255, 0.4);
	align-items: center;
	box-shadow: -2.121px 2.121px 50px 0px rgba(0, 0, 0, 0.5);

	@media only screen and (max-width: 1160px) {
		top: 90px;
	}

	@media only screen and (max-width: 1060px) {
		right: 0;
	}

	@media only screen and (max-width: 990px) {
		flex-direction: column;
		padding: 10px 5px;
		gap: 5px;
		top: 105px;
	}

	@media only screen and (max-width: 450px) {
		flex-direction: row;
		padding: 5px 13px;
		top: 55px;
		right: 0;
		width: fit-content;
		left: 50%;
		transform: translateX(-50%);
	}


	li {
		display: inline-block;
		@include box(25px);
		padding: 0;
		background: none;
		margin: 0 10px;
		transition: 0.4s;

		@media only screen and (max-width: 990px) {
			margin: 0;
		}

		@media only screen and (max-width: 450px) {
			margin: 0 5px;
		}

		&:hover {
			translate: 0 -2px;
		}

		&:nth-last-of-type(1) {
			@include box(36px);
		}

		a {
			display: block;
			@include box(100%);
		}

		#parentpay-head {
			@include box(36px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/parent-pay.png) no-repeat center;
		}

		#twitter-head {
			@include box(25px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/twitter.png) no-repeat center;
		}

		#instagram-head {
			@include box(25px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/instagram.png) no-repeat center;
		}
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(25px);
	overflow: hidden;
	background: url(/i/design/translate.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(25px);
	background: url(/i/design/search.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 120px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;
}

// home page alternate
.body_5716 #mainholder {
	padding: 0;
	margin: 0;
}

// -----------------------------------//
// HOME BTNS
// -----------------------------------//

#home-btns {
	position: absolute;
	right: 0;
	bottom: 150px;
	z-index: 10;
	width: 100%;

	@media only screen and (max-width: 1060px) {
		bottom: auto;
		position: relative;
		padding: 30px 0;
	}

	@media only screen and (max-width: 990px) {
		padding: 50px 0;
	}

	.wrapper {

		ul {
			display: grid;
			grid-template-columns: 1fr repeat(2, 0) repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr) repeat(3, 0);
			grid-column-gap: 0;
			grid-row-gap: 0;
			width: fit-content;
			float: right;

			@media only screen and (max-width: 1060px) {
				grid-template-columns: 1fr;
				grid-template-rows: repeat(5, 1fr);
				margin: 0 auto;
				float: none;
				grid-row-gap: 15px;
			}

			@media only screen and (max-width: 350px) {
				width: 98%;
			}

			li {
				position: relative;
				padding: 0;
				margin: 12px 0 0 12px;
				width: 280px;
				background: pink;
				height: 105px;
				border-radius: 50px;
				border: 4px solid #fff;
				transition: 0.4s;

				@media only screen and (max-width: 1230px) {
					width: 260px;
				}

				@media only screen and (max-width: 1140px) {
					width: 240px;
				}

				@media only screen and (max-width: 1060px) {
					width: 380px;
					margin: 0;
				}

				@media only screen and (max-width: 450px) {
					width: 280px;
				}

				@media only screen and (max-width: 350px) {
					width: 100%;
				}

				&:before {
					position: absolute;
					content: "";
					pointer-events: none;

					@media only screen and (max-width: 400px) {
						display: none;
					}
				}

				&:hover {
					translate: 0 -3px;

					a {
						letter-spacing: 1px;
						text-shadow: 0px 3px 2px #000000;
					}
				}

				&:nth-of-type(1) {
					grid-area: 1 / 4 / 2 / 5;
					background: rgba(108, 157, 49, 0.85);

					&:before {
						background: url(/i/design/home-btns/1.png) no-repeat;
						width: 88px;
						height: 64px;
						top: -17px;
						left: -5px;
					}

					@media only screen and (max-width: 1060px) {
						grid-area: 1 / 1 / 2 / 2;
					}
				}

				&:nth-of-type(2) {
					grid-area: 1 / 5 / 2 / 6;
					background: rgba(85, 60, 127, 0.85);

					&:before {
						background: url(/i/design/home-btns/2.png) no-repeat;
						width: 50px;
						height: 90px;
						bottom: -3px;
						left: 20px;
					}

					@media only screen and (max-width: 1060px) {
						grid-area: 2 / 1 / 3 / 2;
					}
				}

				&:nth-of-type(3) {
					grid-area: 2 / 1 / 3 / 2;
					background: rgba(85, 60, 127, 0.85);

					&:before {
						background: url(/i/design/home-btns/3.png) no-repeat;
						width: 66px;
						height: 84px;
						top: 21px;
						left: -17px;
					}

					@media only screen and (max-width: 1060px) {
						grid-area: 3 / 1 / 4 / 2;
						background: rgba(0, 0, 0, 0.5);
					}
				}

				&:nth-of-type(4) {
					grid-area: 2 / 4 / 3 / 5;
					background: rgba(0, 0, 0, 0.5);

					&:before {
						background: url(/i/design/home-btns/4.png) no-repeat;
						width: 60px;
						height: 80px;
						top: 20px;
						left: 5px;
					}

					a {
						padding: 0 0 0 30px;

						@media only screen and (max-width: 1060px) {
							padding: 0;
						}
					}

					@media only screen and (max-width: 1060px) {
						grid-area: 4 / 1 / 5 / 2;
						background: rgba(85, 60, 127, 0.85);
					}
				}

				&:nth-of-type(5) {
					grid-area: 2 / 5 / 3 / 6;
					background: rgba(108, 157, 49, 0.85);

					&:before {
						background: url(/i/design/home-btns/5.png) no-repeat;
						width: 56px;
						height: 76px;
						top: 20px;
						right: 5px;
					}

					a {
						padding: 0 15px 0 0;

						@media only screen and (max-width: 1060px) {
							padding: 0;
						}
					}

					@media only screen and (max-width: 1060px) {
						grid-area: 5 / 1 / 6 / 2;
					}
				}

				a {
					display: block;
					height: 100%;
					color: #fff;
					text-transform: uppercase;
					font-size: 28px;
					font-weight: 700;
					text-align: center;
					line-height: 105px;

					@media only screen and (max-width: 1230px) {
						font-size: 25px;
					}

					@media only screen and (max-width: 1140px) {
						font-size: 23px;
					}

					@media only screen and (max-width: 1060px) {
						font-size: 30px;
					}
				}
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 15px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 15px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;
	border-radius: 15px;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: url(/i/design/header-bar.png) repeat-y right;
	border-radius: 15px;
	border: 3px solid #fff;
	opacity: 0.95;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: none;
	padding: 0;
	position: absolute;
	bottom: 30px;
	z-index: 10;
	width: 100%;

	@media only screen and (max-width: 1060px) {
		position: relative;
		bottom: auto;
		padding: 30px 0;
	}

	@media only screen and (max-width: 990px) {
		background: rgba(85, 60, 127, 0.95);
		padding: 50px 0;
	}

	.wrapper {
		text-align: left;
		font-size: 18px;
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media only screen and (max-width: 990px) {
			text-align: center;
		}

		.top {
			display: flex;
			font-size: 16px;
			line-height: 19px;
			gap: 30px;
			align-items: flex-end;

			@media only screen and (max-width: 990px) {
				flex-direction: column;
				align-items: center;
			}

			p.address {
				span {
					display: block;
					font-size: 22px;
					font-weight: 700;
				}
			}

			p.telephone {}

			a[href^="mailto:"] {
				display: block;

				span {
					color: #6D9D31;
					font-weight: bolder;

				}
			}
		}

		.btm {
			display: flex;
			font-size: 12px;
			font-weight: 600;
			justify-content: space-between;
			align-items: center;

			@media only screen and (max-width: 990px) {
				flex-direction: column;
			}
		}

		p,
		a {
			margin: 0;
			color: #fff;
		}

		img {
			display: inline-block;
			margin: 0 0 4px 5px;
		}
	}



}


// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;