.video-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 750px;
  pointer-events: none;
  overflow: hidden;

  // for top of page gradient
  &:before {
    @include before(100%, 354px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 337px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }
}

.video-background iframe {
  height: 130%;
  top: -15%;
  left: 0;
  right: 0;
  margin: 0 auto;
  object-fit: fill;
}

@media only screen and (max-width: 700px) {
  .video-background {
    display: none;
  }
}

// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_5716 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 760px;

  @media only screen and (max-width: 1060px) {
    height: 90vh;
  }

  @media only screen and (max-width: 990px) {}
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 354px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 337px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover !important;
    animation: zoom 30s ease forwards infinite;

    @keyframes zoom {
      from {
        transform: scale(1)
      }

      to {
        transform: scale(1.1)
      }
    }
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 25px;
  right: 0;
  bottom: 80px;
  margin: 0 auto;

  @media only screen and (max-width: 1500px) {
    left: -40px;

  }

  @media only screen and (max-width: 1320px) {
    bottom: 100px;
    left: -20px;
  }

  @media only screen and (max-width: 1060px) {
    left: 0;
  }

  @media only screen and (max-width: 990px) {
    bottom: 0;
  }

  img {

    @media only screen and (max-width: 1500px) {
      width: 500px;
    }

    @media only screen and (max-width: 1320px) {
      width: 350px;
    }

    @media only screen and (max-width: 1080px) {
      width: 320px;
    }

    @media only screen and (max-width: 1060px) {
      margin: 0 auto;
    }
  }

}